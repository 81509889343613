import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PaninfoPageRoutingModule } from './paninfo-routing.module';

import { PaninfoPage } from './paninfo.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PaninfoPageRoutingModule
  ],
  declarations: [PaninfoPage]
})
export class PaninfoPageModule {}
