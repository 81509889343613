import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';
import { ApiService } from "../http/api.service";
import { ApiConfiguration } from "../http/api-configuration";

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  json: any = {
    type: 2, //1: Update 2: maintenance,
    current: '1.0.0',
    message: {
      msg: "There is a Majour update in our app. Please update to continue", //text will change for maintenance
      btn: "Update"
    }
  }

  version;
  appInfo: any = {}
  constructor(
    private alertController: AlertController,
    private appVersion: AppVersion,
    private plt: Platform,
    private market: Market,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
  ) { }

  // source 3 - android
  // source 2 - ios

  async descideUpdateAction(source) {

    
    this.version = await this.appVersion.getVersionNumber();
    

    // ****API INTEGRATION*******
    this.apiService.get(this.apiConfig.getAppInfo)
      .subscribe((res: any) => {
        // debugger
        this.appInfo = res.data.app_info[0]

        // If type is update
        if (this.appInfo.type == 1) { 
          // if type is ios
          if(source === 3){
            //current_version
            if (parseFloat(this.appInfo.current_ios_version) > parseFloat(this.version)) return this.majorUpdate(source); 
          } else{
            if (parseFloat(this.appInfo.current_android_version) > parseFloat(this.version)) return this.majorUpdate(source);
          }
        }

        //  Maintainance
        if (this.appInfo.type == 2) return this.MaintainceAlert(source)


      }, err => {
        console.log(err)
      });
  }

  async majorUpdate(source) {
    const isAlertPresent = await this.alertController.getTop();
    if (isAlertPresent) return
    
    const alert = await this.alertController.create({
      cssClass: 'singlebtn-alert-custom-class py-success-alert updateAlert',
      message: `There is a Major update in our app. Please update to continue`,
      buttons: [{
        text: ' Update ',
        cssClass: 'okBtnclass',
      }
    ],

      backdropDismiss: false
    });

    await alert.present();

    await alert.onDidDismiss().then(() => this.updateBlock(source));
  }

  updateBlock(source) {
    if (source === 2) {
      this.market.open('com.nestleloyalty.app');
    } 
    if(source === 3) {
      this.market.open('id6670312509');
    }
  }



  async MaintainceAlert(source) {
    const isAlertPresent = await this.alertController.getTop();
    if (isAlertPresent) return

    const alert = await this.alertController.create({
      cssClass: 'singlebtn-alert-custom-class py-success-alert updateAlert',
      message: `App is under maintenance, Will be back online shortly`,
      // buttons: ['Close'],

      backdropDismiss: false
    });

    await alert.present();

    await alert.onDidDismiss().then();
  }

}























