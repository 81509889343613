import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OfferCodeDetailsPageRoutingModule } from './offer-code-details-routing.module';

import { OfferCodeDetailsPage } from './offer-code-details.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    OfferCodeDetailsPageRoutingModule
  ],
  declarations: [OfferCodeDetailsPage]
})
export class OfferCodeDetailsPageModule {}
