import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.page.html',
  styleUrls: ['./order-success.page.scss'],
})
export class OrderSuccessPage implements OnInit {
  messsage:any
  constructor(
    private modalController: ModalController,
    private analytics: AnalyticsProvider,
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.analytics.trackScreen('OrderSuccessPage')
  }

  closeModal() {
    this.modalController.dismiss()
  }
}
