import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';

@Component({
  selector: 'app-otpverification',
  templateUrl: './otpverification.page.html',
  styleUrls: ['./otpverification.page.scss'],
})
export class OtpverificationPage implements OnInit {
  otp:any='';
  constructor(private modalController: ModalController,
    private analytics: AnalyticsProvider,
    ) { }

  ngOnInit() {
  }

  ionViewDidEnter(){
    this.analytics.trackScreen('OtpverificationPage')
  }
  submitOTP() {
    console.log(this.otp,'dddd')
    this.closeModal('otp')
  }

  closeModal(state?) {
    this.modalController.dismiss(state)
  }

}
