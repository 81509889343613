import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class WheelService {
  _defaultOpts = [
    "Diego",
    "Ignacio",
    "JaviGo",
    "Mendi",
    "Sergio",
    "Virginia",
    "Tod@s"
  ];

  optionSource: BehaviorSubject<String[]>;
  option$ : any = {};

  winnersSource: BehaviorSubject<String[]>;
  winner$: Observable<String[]>;

  constructor() {
    this.optionSource = new BehaviorSubject(this.getOptions());
    this.option$ = this.optionSource.asObservable();

    this.winnersSource = new BehaviorSubject([]);
    this.winner$ = this.winnersSource.asObservable();
  }


  restartWinners() {
    this.winnersSource.next([]);
  }

  persistOptions() {
    localStorage.setItem("OPTS", JSON.stringify(this.optionSource.getValue()));
  }

  getOptions(): String[] {
    const value = localStorage.getItem("OPTS");
    return value ? JSON.parse(value) : this._defaultOpts;
  }

  resetToDefault() {
    this.optionSource.next(this._defaultOpts);
  }
  
}
