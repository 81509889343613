import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';

@Component({
  selector: 'app-pan-verification',
  templateUrl: './pan-verification.page.html',
  styleUrls: ['./pan-verification.page.scss'],
})
export class PanVerificationPage implements OnInit {
  successDetail
  constructor(private modalController: ModalController,
    private analytics: AnalyticsProvider,
    ) { }

  ngOnInit() {
  }
  ionViewDidEnter(){
    console.log(this.successDetail,'oooo')
    this.analytics.trackScreen('PanVerificationPage')
  }

  closeModal(state?) {
    let data = {type: state}
    this.modalController.dismiss(data)
  }
}
