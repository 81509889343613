import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VoucherCodeDetailsPageRoutingModule } from './voucher-code-details-routing.module';

import { VoucherCodeDetailsPage } from './voucher-code-details.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    VoucherCodeDetailsPageRoutingModule,
    TranslateModule
  ],
  declarations: [VoucherCodeDetailsPage]
})
export class VoucherCodeDetailsPageModule { }
