import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';

@Component({
  selector: 'app-paninfo',
  templateUrl: './paninfo.page.html',
  styleUrls: ['./paninfo.page.scss'],
})
export class PaninfoPage implements OnInit {
  content:any
  userdata:any
  constructor(private modalController: ModalController,
    private analytics: AnalyticsProvider,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    ) { }

  ngOnInit() {
  }
  ionViewDidEnter(){
    this.getTds();
  }

  closeModal() {
    this.modalController.dismiss()
  }
  getTds() {
    this.apiService.get(this.apiConfig.getTermsAndWelcomeVideo + `?mobile_no=${this.userdata.mobile_no}`)
      .subscribe((res: any) => {
        this.content = res.data[4]
      }, err => {
      });
  }
}
