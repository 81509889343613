import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from "@angular/core";

@Component({
  selector: 'app-spin-wheel',
  templateUrl: './spin-wheel.component.html',
  styleUrls: ['./spin-wheel.component.scss'],
})
export class SpinWheelComponent implements AfterViewInit {
  // custom variables to calculate stop of spin wheel
  lengthOfWheel
  oneSegmentValue
  stopAt
  distanceToCover
  halfOfOneSegmentValue

  spin_colors: any = []
  @Input() winningPosition
  @Input() rewardColors

  @Input() set options(values: string[]) {
    setTimeout(() => {
      this.calculateWheelParameters()
      this.sectors = values.map((opts, i) => {
        return {
          color: this.rewardColors[(i >= this.rewardColors.length ? i + 1 : i) % this.rewardColors.length],
          label: opts
        };
      });

      if (this.wheel) {
        this.createWheel();
      }
    }, 200);
  }

  @ViewChild("wheel") wheel: ElementRef<HTMLCanvasElement>;
  @ViewChild("spin") spin: ElementRef;
  colors = this.spin_colors
  sectors: any[] = [];

  rand = (m, M) => Math.random() * (M - m) + m;
  tot;
  ctx;
  dia;
  rad;
  PI;
  TAU;
  arc0;

  winners = [];

  modeDelete = true;

  friction = 0.995; // 0.995=soft, 0.99=mid, 0.98=hard
  angVel = 0; // Angular velocity
  ang = 0; // Angle in radians
  lastSelection;

  constructor(
    // private dataService: WheelService
  ) { }

  ngDoCheck(): void {
  }

  ngOnInit() {
    // Initial rotation
    // Start engine
    this.spin_colors = this.rewardColors
  }
  ngAfterViewInit(): void {
    this.createWheel();
  }

  createWheel() {
    this.ctx = this.wheel.nativeElement.getContext("2d");
    this.dia = this.ctx.canvas.width;
    this.tot = this.sectors.length;
    this.rad = this.dia / 2;
    this.PI = Math.PI;
    this.TAU = 2 * this.PI;

    this.arc0 = this.TAU / this.sectors.length;
    this.sectors.forEach((sector, i) => this.drawSector(sector, i));
  }


  getIndex = () => Math.floor(this.tot - (this.ang / this.TAU) * this.tot) % this.tot;

  drawSector(sector, i) {
    const ang = this.arc0 * i;
    this.ctx.save();
    // COLOR
    this.ctx.beginPath();
    this.ctx.fillStyle = sector.color;
    this.ctx.moveTo(this.rad, this.rad);

    this.ctx.arc(this.rad, this.rad, this.rad, ang, ang + this.arc0);
    this.ctx.lineTo(this.rad, this.rad);
    this.ctx.fill();
    // TEXT
    this.ctx.translate(this.rad, this.rad);
    this.ctx.rotate(ang + this.arc0 / 2);
    this.ctx.textAlign = "right";
    this.ctx.fillStyle = "#fff";
    this.ctx.font = "bold 30px sans-serif";
    this.ctx.fillText(sector.label, this.rad - 10, 10);
    //
    this.ctx.restore();
  }



  spinner() {
    var element = document.getElementById('wheel');
    element.classList.add('rotating');
    this.test()
  }


  calculateWheelParameters() {
    this.lengthOfWheel = this.rewardColors.length
    this.oneSegmentValue = (360 / this.lengthOfWheel)
    this.distanceToCover = (this.lengthOfWheel - this.winningPosition) * this.oneSegmentValue
    this.halfOfOneSegmentValue = this.oneSegmentValue / 2
    let initialTenSpins = 360 * 10;
    this.stopAt = initialTenSpins + this.distanceToCover + this.halfOfOneSegmentValue + 270
  }

  test() {
    // Define keyframes
    const keyframes = [
      { transform: 'rotate(270deg)' },
      { transform: `rotate(${this.stopAt}deg)` }
    ];

    const options: KeyframeAnimationOptions = {
      duration: 6000, // 6 seconds
      iterations: 1, // Number of iterations (1 for one-time animation)
      fill: 'forwards', // Maintain the final state after the animation
      easing: 'cubic-bezier(0.42, 0, 0.58, 1)' // Custom timing function
    };

    let box = document.getElementById('wheel');
    const animation = box.animate(keyframes, options);

  }

}