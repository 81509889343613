import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';

@Component({
  selector: 'app-why-tds',
  templateUrl: './why-tds.page.html',
  styleUrls: ['./why-tds.page.scss'],
})
export class WhyTDSPage implements OnInit {
  successDetail: any
  userName: any
  flag: any
  constructor(
    private modalController: ModalController,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private analytics: AnalyticsProvider 
    ) { }
    ionViewDidEnter() {
      this.analytics.trackScreen('WhyTDSPage')
    }

  ngOnInit() {
  }
  ionViewWillEnter() {

  }



  closeModal() {
    this.modalController.dismiss()
  }





}
