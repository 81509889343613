import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'providers/analytics/analytics';
import { ConfirmAlertService } from 'src/app/services/confirmAlert/confirm-alert.service';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

@Component({
  selector: 'app-dinominations-modal',
  templateUrl: './dinominations-modal.page.html',
  styleUrls: ['./dinominations-modal.page.scss'],
})
export class DinominationsModalPage implements OnInit {

  singleProduct
  points:any = null
  denominations:any
  userDetails: any
  finalSubmit = false
  typeOfProduct:any
  constructor(
    private modalController: ModalController,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private alertService: ConfirmAlertService,
    private analytics: AnalyticsProvider,

  ){}

  ngOnInit(): void {
    
  }

  ionViewDidEnter(){
      this.userDetails = JSON.parse(localStorage.getItem('login-details'))
      this.initialize();
      console.log(this.typeOfProduct)
      this.analytics.trackScreen('DinominationModalPage')
  }

  initialize(){
    this.points = 0;
    this.singleProduct.Denomination.forEach(prod => {
      prod.qty = 0
      // this.points += (prod.qty * prod.points)      
    });
  }


  calculateTotal(){
    this.points = 0
    this.singleProduct.Denomination.forEach(prod => {
      this.points += (prod.qty * prod.points)      
    });
  }

  productQty(e, i){
    let qty = parseInt(e.target.value)
    this.singleProduct.Denomination[i].qty = qty
    this.calculateTotal()
   
  }

  formProductData(){
    let products = []
    this.singleProduct.Denomination.forEach(denominations => {
      if(denominations.qty != 0){
        products.push(JSON.parse(JSON.stringify(denominations)))
        }
      });
      this.denominations= {
         'product_id' : this.singleProduct.product_id,
         'uid': this.userDetails.user_id,
         'program_id': this.userDetails.program_id,
         'cartdetails' : products
       }
        
    if(this.denominations.cartdetails!=''){
      this.addToCart(this.denominations)
    }else{
      this.alertService.presentAlert('Please Select Quantity')
    }
  }


  addToCart(cartData){
    this.finalSubmit = true
    this.apiService.post(this.apiConfig.savecart_rewards, cartData)
    .subscribe((res:any)=>{
      this.finalSubmit = false
        this.closeModal('added');
    },err=>{

    })
  }

  closeModal(data){
    this.points = 0
    this.modalController.dismiss(data)
  }
}