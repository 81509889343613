import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';

@Component({
  selector: 'app-successpage',
  templateUrl: './successpage.page.html',
  styleUrls: ['./successpage.page.scss'],
})
export class SuccesspagePage implements OnInit {
  messsage:any
  constructor(private modalController: ModalController,
    private analytics: AnalyticsProvider,
    ) { }

  ngOnInit() {
  }
  ionViewDidEnter(){
  }

  closeModal() {
    this.modalController.dismiss()
  }
}
