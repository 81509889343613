import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment/moment.js';
import { AnalyticsProvider } from 'providers/analytics/analytics';

@Component({
  selector: 'app-offer-code-details',
  templateUrl: './offer-code-details.page.html',
  styleUrls: ['./offer-code-details.page.scss'],
})
export class OfferCodeDetailsPage implements OnInit {
  offerData: any
  summary: any
  type: any
  constructor(
    private modalController: ModalController,
    private analytics: AnalyticsProvider,

  ) { }

  header: any = ['Denomination', 'Offer Code', 'Offer Code Pin', 'Expiry Date']

  ngOnInit() {
    console.log(this.type)
    this.analytics.trackScreen('OfferCodeDetails')
  }

  ionVieDidEnter() {

  }

  decode(code) {
    return atob(code);

  }

  dismiss() {
    this.modalController.dismiss();
  }

  formatDate(date) {
    return moment(date).format('MMM DD YYYY hh:mm')
  }
}
