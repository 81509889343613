import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-coupon-success-modal',
  templateUrl: './coupon-success-modal.page.html',
  styleUrls: ['./coupon-success-modal.page.scss'],
})
export class CouponSuccessModalPage implements OnInit {
  couponData: any = null;
  getPointData: any;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.getPointData = this.couponData.data;
    //console.log("couponData= ", this.getPointData);
  }

  myWinnings() {
    this.modalController.dismiss();
  }

}
