import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { AnalyticsProvider } from 'providers/analytics/analytics';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.page.html',
  styleUrls: ['./ticket-detail.page.scss'],
})
export class TicketDetailPage implements OnInit {
  ticketData: any
  comment:any
  constructor(
    private activateRoute : ActivatedRoute,
    private analytics: AnalyticsProvider ,
    private modalController: ModalController,
    private apiService:ApiService,
    private apiConfig:ApiConfiguration

    ) { }
    ionViewDidEnter() {
      this.ticketList();
      console.log('lll')
      this.analytics.trackScreen('TicketDetailPage')
    }
  ngOnInit() {
  }
  


  formatMatchDate(date) {
    return moment(date).format('dddd, MMMM Do YYYY, h:mm:ss a')
  }
  formatedDate(date){
    return moment(date).format('MMMM Do YYYY')
  }
  getClass(status){
    if(status == 0) return "pen"
    // if(status == 1) return "inc"
    if(status == 1) return "app"
    if(status == 2) return "rej"

  }
  ticketList() {
    this.apiService.get(this.apiConfig.ticketsList+`&user_id=${this.ticketData.user_id}&ticket_id=${this.ticketData.ticket_id}`)
      .subscribe( (res: any) => {
        console.log(res.data)
        this.ticketData =  res.data[0]
      })
  }

 replayRequest(){
  let payload = {
    user_id:this.ticketData.user_id,
    ticket_id:this.ticketData.ticket_id,
    comment:this.comment
  }
  this.apiService.post(this.apiConfig.replayTicket,payload)
  .subscribe( (res: any) => {
    console.log(res.data)
    this.comment = ''
    this.ticketList()
  })
 }

   closeModal() {
    this.modalController.dismiss();
  }
}
