import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Subscription } from 'rxjs';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsProvider } from 'providers/analytics/analytics';


@Component({
  selector: 'app-voucher-code-details',
  templateUrl: './voucher-code-details.page.html',
  styleUrls: ['./voucher-code-details.page.scss'],
})
export class VoucherCodeDetailsPage implements OnInit {

  model_data
  voucher_details: any = {};
  private backButtonSub: Subscription;

  constructor(
    public modalController: ModalController,
    private config: ApiConfiguration,
    private service: ApiService,
    private iab: InAppBrowser,
    private screenOrientation: ScreenOrientation,
    private platform: Platform,
    public toaster: ToasterService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private analytics: AnalyticsProvider,


  ) { }

  ngOnInit() {
    this.getVpucherDetails();
    this.analytics.trackScreen('VoucherCodeDetailsPage')
  }

  getVpucherDetails() {
    let url = this.config.reward_order_voucher_details + this.model_data.order_access_token;
    // console.log(url);
    this.service.get(url).subscribe((response: any) => {
      //console.log(response);
      this.voucher_details = response.data;
    },
      err => {
        // console.log('Error:', err);
      });
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true,
      status: false
    });
  }

  viewTermAndConditions(tc_Url) {
    if (tc_Url != null) {
      let sampleurl = tc_Url;
      let browserOptions: any = { location: 'no' }
      const browser = this.iab.create(sampleurl, '_self', browserOptions);
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      this.backButtonSub = this.platform.backButton.subscribeWithPriority(1, () => {
        this.backButtonSub.unsubscribe();
      });
    } else {
      this.toaster.presentToast(this.translateService.instant('rewards.term_url_not_found'));
    }
  }
}
