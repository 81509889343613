import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import{BackButtonComponent} from './back-button/back-button.component';
import{BrandCardComponent} from './brand-card/brand-card.component';
import {CardLayoutValuesComponent} from './card-layout-values/card-layout-values.component';
import{ CardlistComponent}from './cardlist/cardlist.component';
import {DateSearchComponent } from './date-search/date-search.component';
import{ ImageCardComponent} from './image-card/image-card.component';
import{InvoiceCompletedCardComponent} from './invoice-completed-card/invoice-completed-card.component';
import {InvoiceSliderComponent} from './invoice-slider/invoice-slider.component';
import {OrdersCardComponent} from './orders-card/orders-card.component';
import { OutletQuizComponent} from './outlet-quiz/outlet-quiz.component';
import{ImagePreviewPage} from './image-preview/image-preview.page';
// import {PopupModelPage } from './popup-model/popup-model.page';
// import{ PrimaryButtonComponent} from './primary-button/primary-button.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import{QuizComponent}from './quiz/quiz.component';
import {SalesInvoiceComponentComponent} from './sales-invoice-component/sales-invoice-component.component';
import {SampleCardComponent} from './sample-card/sample-card.component';
import {SearchBoxComponent} from './search-box/search-box.component';
import {TaskStatusComponent} from './task-status/task-status.component';
import { ContestImagePreviewComponent } from './contest-image-preview/contest-image-preview.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { SpinWheelComponent } from './spin-wheel/spin-wheel.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    ReactiveFormsModule, FormsModule
  ],
  declarations: [
    BackButtonComponent,
    BrandCardComponent,
    CardLayoutValuesComponent,
    CardlistComponent,
    DateSearchComponent,
    ImageCardComponent,
    InvoiceCompletedCardComponent,
    InvoiceSliderComponent,
    OrdersCardComponent,
    OutletQuizComponent,
    SpinWheelComponent,
    // PopupModelPage,
    // PrimaryButtonComponent,
    ProgressBarComponent,QuizComponent,SalesInvoiceComponentComponent,
    SampleCardComponent,
    SearchBoxComponent,
    TaskStatusComponent,
    ContestImagePreviewComponent,
    VideoPlayerComponent
  ],
  exports: [
    BackButtonComponent,
    BrandCardComponent,
    CardLayoutValuesComponent,
    CardlistComponent,
    DateSearchComponent,
    ImageCardComponent,
    InvoiceCompletedCardComponent,
    InvoiceSliderComponent,
    OrdersCardComponent,
    OutletQuizComponent,
    SpinWheelComponent,
    // PopupModelPage,
    // PrimaryButtonComponent,
    ProgressBarComponent,QuizComponent,SalesInvoiceComponentComponent,
    SampleCardComponent,
    SearchBoxComponent,
    TaskStatusComponent,
    ContestImagePreviewComponent,
    VideoPlayerComponent
  ]
})
export class ComponentsModule {
}
