import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ApiConfiguration } from "../../../services/http/api-configuration";
import { ApiService } from "../../../services/http/api.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { AnalyticsProvider } from 'providers/analytics/analytics';

@Component({
  selector: 'app-reward-verification-modal',
  templateUrl: './reward-verification-modal.page.html',
  styleUrls: ['./reward-verification-modal.page.scss'],
})
export class RewardVerificationModalPage implements OnInit {
  formGroup
  otpDetails;
  rewardStatusDetails: any = {}
  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private modalController: ModalController,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToasterService,
    private analytics: AnalyticsProvider,

    ) { }

    ngOnInit() {
      this.formGroup = this.fb.group({
        otp: new FormControl(this.otpDetails.otp,  [Validators.required, Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]),
      });
      this.analytics.trackScreen('RewardVerificationModalPage')
    }
    
    get f() {
      return this.formGroup.controls;
    }

    closeModal(){
      this.modalController.dismiss();
    }

    submitted = false;
    confirmRewardOtp(formData){
      this.submitted = true;
      if (this.formGroup.invalid) {
        return;
      }

      formData.orderdetail_id = this.otpDetails.orderdetail_id
      this.apiService.post(this.apiConfig.verifyRewardOtp, formData)
      .subscribe((res:any)=>{
        this.rewardStatusDetails = res.data
        this.rewardStatusDetails.status = 'success'
        this.modalController.dismiss(this.rewardStatusDetails)
      },err=>{ 
        this.rewardStatusDetails.status = 'failed'
        this.toast.presentToast(err.data.message)
      })
    }

}
