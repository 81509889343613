import { ChangeDetectorRef, Component } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authguard/authentication.service';
import { Router } from '@angular/router';
import { UpdateService } from "./services/update/update.service";
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language/language.service';
import { MediaMatcher } from '@angular/cdk/layout';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {


  language_key: any;

  source_from: any
  isSmallScreen: boolean;
  private readonly mobileQuery: MediaQueryList;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private updateService: UpdateService,
    private transLateService: TranslateService,
    private languageService: LanguageService,
    private toastController: ToastController,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.initializeApp();
    this.languageService.setInitialAppLanguage();
    this.mobileQuery = media.matchMedia('(max-width: 720px)');
    this.isSmallScreen = this.mobileQuery.matches;

    this.mobileQuery.addEventListener('change', () => {
      changeDetectorRef.detectChanges();
      this.isSmallScreen = this.mobileQuery.matches;
    });
  }

  ngAfterViewInit() {
    this.platform.ready().then(() => {
      this.hideScrollbars();
    });
  }

  hideScrollbars() {
    const contents = document.querySelectorAll('ion-content');
    contents.forEach((content: any) => {
      const scrollContent = content.shadowRoot?.querySelector('.scroll-content');
      if (scrollContent) {
        scrollContent.style.overflow = 'hidden'; // Disable scrolling
        scrollContent.style.scrollbarWidth = 'none'; // For Firefox
        scrollContent.style.webkitOverflowScrolling = 'touch'; // For iOS touch scrolling
      }
    });
  }

  initializeApp() {
    window.localStorage.setItem("brand_name", "Bigcity");
    this.platform.ready().then(() => {
      // check for updates
    
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.splashScreen.hide();
      
      if (this.platform.is('android')) {
        this.source_from = 2;
      }
      else if (this.platform.is('ios')) {
        this.source_from = 3;
      }
      else {
        this.source_from = 4;
      }
      // check for updates
      if (this.platform.is("android") || this.platform.is("ios") ){
        this.updateService.descideUpdateAction(this.source_from);
      }
      // this.checkAndRedirect();
      this.authenticationService.authenticationState.subscribe(state => {
        if (state) {
          this.router.navigate(['/tabs-page/dashboard']);
         //this.router.navigate(['/tabs-page/dashboard/simulator']);
          //this.router.navigate(['/tabs-page/dashboard/rewards/reward-products/18/EVoucher']);
          //this.router.navigate(['/tabs-page/customer-service']);
          
          
        } else { 
          //this.router.navigate(['/onboarding/landing-page']);
          this.router.navigate(['/onboarding/mobile-number']);
         // this.router.navigate(['/onboarding/terms-and-conditions'])
          
        }
      });
      if (this.platform.is('cordova')) {
        FCM.getToken().then(token => {
          debugger
          localStorage.setItem('fcm', token)
        }).catch(err =>{ 
          // console.log('err:::', err)
        });

        FCM.onNotification().subscribe(data => {
          if (data.wasTapped) {
            // console.log("Received in background");
          } else {
            // console.log("Received in foreground");
            this.pushLocalNotification(data)
          };
        });

        FCM.onTokenRefresh().subscribe(token => {
          // backend.registerToken(token);

          // console.log('refresh token', token)
          localStorage.setItem('fcm', token)
        });

        FCM.hasPermission().then(hasPermission => {
          if (hasPermission) {
            // console.log("Has permission!");
          } else {
            // console.log('No permission')
            FCM.requestPushPermission().then((res) => {
              // console.log("Permission accepted")
            }, (err) => {
              // console.log("Permission rejected")
            })
          }
        })
        FCM.clearAllNotifications();
      }

      // let status bar overlay webview
      // this.statusBar.overlaysWebView(true);
      // set status bar to white
      this.statusBar.backgroundColorByHexString('#1A1A1A');
    });
    this.platform.resume.subscribe(async () => {
      //Check for updates
      if (this.platform.is("android") || this.platform.is("ios") ){
        this.updateService.descideUpdateAction(this.source_from);
      }
    });

    let language = 'en'
    this.transLateService.use(language);
    this.callTranslationEvent();
  }

  async checkAndRedirect() {
    let state = await this.authenticationService.isAuthenticated();
    //console.log(state);

  }


  pushLocalNotification(data){
    console.log('notification data::', data)
    // this.localNotifications.schedule({
    //   foreground: true,
    //   trigger: {at: new Date(new Date().getTime() + 100)},
    //   title: 'My first notification',
    //   text: 'Thats pretty easy...',
    // });

    // this.localNotifications.fireEvent
    // console.log('local notification triggered')
    this.presentToastWithOptions(data)
  }

  async presentToastWithOptions(data) {
    const toast = await this.toastController.create({
      header: 'Club Wholesale',
      message: data.body,
      position: 'top',
      cssClass: 'notification-alert',
      duration: 5000,
      buttons: [
        {
          side: 'end',
          // icon: 'star',
          cssClass: 'notification-button',
          text: 'VIEW',
          handler: () => {
            this.router.navigateByUrl('/tabs-page/dashboard/notification')
          }
        }
      ]
    });
    toast.present();
  }


  callTranslationEvent() {
    this.transLateService.setDefaultLang('en');
    this.languageService.$changeLanguage.subscribe((lang) => {
      this.language_key = lang;
      //console.log("change Language = ", this.language_key);
      if (this.language_key['type'] == 'language' && this.language_key['data'] == 'en') {
        this.transLateService.use(this.language_key['data']);
      } else if (this.language_key['type'] == 'language' && this.language_key['data'] == 'tm') {
        this.transLateService.use(this.language_key['data'])
      }

    })


  }
}
